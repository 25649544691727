/* Header.css */

.container {
    max-width: 100%;
  }
  
  .logo img {
    max-height: 40px; /* Adjusted the height to make the logo smaller */
  }
  
  .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  
  .open-nav {
    cursor: pointer;
  }
  
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  